export const da = {
  // Views folder
  view: {
    kindeError: {
      title: 'Fejl med brugeren',
      body: 'Der er sket en eller anden fejl ved authentication med Kinde, prøv at logge ud og på igen',
    },
    userProfileIndex: {
      title: 'Profil',
      fetchData: 'Henter data',
      userInfo: 'Bruger info fra Kinde',
      changePassword: {
        title: 'Skift kodeord',
      },
    },
    accountsList: {
      title: '@:common.common.account.term',
      addAccount: 'Tilføj @.lower:common.common.account.term',
    },
    accountShow: {
      title: ' @:common.common.account.term',
      publicData: 'Offentlige data vist på Ejendomstorvet',
      privateData: 'Ejendomstorvet',
      labels: {
        id: '@:common.common.id',
        type: '@:common.common.type',
        name: '@:common.common.name',
        email: '@:common.common.email offentlig',
        ccEmail: 'Sekundær @:common.common.email kontakt',
        website: '@:common.common.website',
        phoneNumber: '@:common.common.phoneNumber',
        address: '@:common.common.address.term',
        accountType: '@:common.common.account.type',
        organization: 'Tilknyttet @.lower:common.common.organization.term',
        user: 'Tilknyttede @.lower:common.common.user.term',
        listingSupplier: 'Tilknyttede @.lower:common.common.listingSupplier.term',
        billingId: '@:common.common.billingId.long',
        description: '@:common.common.description',
      },
    },

    accountForm: {
      title: {
        create: 'Tilføj @.lower:common.common.account.term',
        update: 'Opdater @.lower:common.common.account.term',
      },
      publicData: 'Offentlige data vist på Ejendomstorvet',
      privateData: 'Ejendomstorvet',
      labels: {
        name: '@:common.common.name',
        email: '@:common.common.email offentlig',
        ccEmail: 'Sekundær @:common.common.email kontakter',
        phoneNumber: '@:common.common.phoneNumber',
        website: '@:common.common.website',
        description: '@:common.common.description',
        address: '@:common.common.address.term',
        addressPlaceholder: 'Indtast adresse her',
        streetName: '@:common.common.address.streetName',
        streetNumber: '@:common.common.address.streetNumber',
        floor: '@:common.common.address.floor',
        door: '@:common.common.address.floorSide',
        zipCode: '@:common.common.address.zipCode',
        zipCodeName: '@:common.common.address.city',
        logourl: 'Logo',
        accountType: '@:common.common.account.type',
        accountTypePlaceholder: 'Vælg en @:common.common.account.type',
        organization: '@:common.common.organization.term',
        organizationPlaceholder: 'Vælg en @:common.common.organization.term',
        billingId: '@:common.common.billingId.long',
      },
    },

    apiLogs: {
      title: 'API logs',
    },
    homePage: {
      title: 'Ejendomstorvet admin site',
    },
    notFound: {
      title: '404',
      message: 'Siden du leder efter findes ikke',
    },
    listingSupplierIndex: {
      title: '@:common.common.listingSupplier.term',
      addListingSupplier: 'Tilføj @.lower:common.common.listingSupplier.term',
    },
    listingIndex: {
      title: 'Emner',
      addListing: 'Nyt @.lower:common.common.listing.term',
      labels: {
        account: '@:common.common.account.term',
        selectAccount: 'Vælg en @.lower:common.common.account.term',
        accountOwnerListingIdentifierID: '@:common.common.accountOwnerListingIdentifierID',
        address: '@:common.common.address.term',
        status: '@:common.common.status',
        selectStatus: 'Vælg @.lower:common.common.status',
        selectListingType: 'Vælg @.lower:common.common.listingType.term',
      },
    },
    listingsCreate: {
      title: 'Tilføj nyt @.lower:common.common.listing.term',
      labels: {
        rentalListing: '@.lower:common.common.rentalListing.term',
        investmentListing: '@.lower:common.common.investmentListing.term',
        salesListing: '@.lower:common.common.salesListing.term',
        officespaceListing: '@.lower:common.common.officespaceListing.term',
      },
    },
    listingSupplierShow: {
      title: 'Annonceleverandør',
      labels: {
        id: '@:common.common.id',
        name: '@:common.common.name',
        email: '@:common.common.email',
        authId: '@:common.common.authId',
        account: 'Tilknyttede @.lower:common.common.account.term',
      },
    },

    notAdmin: {
      title: 'Kun adgang for et-admin',
      message: 'Hep du er ikke admin.... go away',
      button: 'Log ind som anden bruger',
    },

    organizationIndex: {
      title: '@:common.common.organization.term',
      addOrganization: 'Tilføj @.lower:common.common.organization.term',
    },

    organizationShow: {
      title: '@:common.common.organization.term',
      labels: {
        id: '@:common.common.id',
        name: '@:common.common.name',
        email: '@:common.common.email',
        account: '@:common.common.account.term',
        delete: 'Slet @.lower:common.common.organization.term',
      },
      messages: {
        confirm: 'Er du sikker på du vil slette denne @.lower:common.common.organization.term',
        accountAssociated: 'Kan ikke slette @.lower:common.common.organization.term når denne @.lower:common.common.organization.term har en konto associeret',
      },
    },

    investmentListingForm: {
      titleCreate: 'Tilføj @.lower:common.common.investmentListing.term',
      titleUpdate: 'Opdater @.lower:common.common.investmentListing.term',
    },
    rentalListingForm: {
      titleCreate: 'Tilføj @.lower:common.common.rentalListing.term',
      titleUpdate: 'Opdater @.lower:common.common.rentalListing.term',
    },
    salesListingForm: {
      titleCreate: 'Tilføj @.lower:common.common.salesListing.term',
      titleUpdate: 'Opdater @.lower:common.common.salesListing.term',
    },
    officespaceListingForm: {
      titleCreate: 'Tilføj @.lower:common.common.officespaceListing.term',
      titleUpdate: 'Opdater @.lower:common.common.officespaceListing.term',
    },

    officespaceListingsClose: {
      labels: {
      },
    },
    rentalListingsClose: {
      labels: {
        yearlyRent: 'Årlig leje {\'(\'}@.lower:common.common.kr{\')\'}',
        yearlyOperatingCost: '@:common.common.yearlyOperatingCost {\'(\'}@.lower:common.common.kr{\')\'}',
        compensation: 'Afståelsespris {\'(\'}@.lower:common.common.kr{\')\'}',
      },
    },
    salesListingsClose: {
      labels: {
        salesPrice: '@:common.common.salesPrice {\'(\'}@.lower:common.common.kr{\')\'}',
        yearlyOperatingCost: '@:common.common.yearlyOperatingCost {\'(\'}@.lower:common.common.kr{\')\'}',
      },
    },
    investmentListingsClose: {
      labels: {
        salesPrice: '@:common.common.salesPrice {\'(\'}@.lower:common.common.kr{\')\'}',
        yearlyOperatingCost: '@:common.common.yearlyOperatingCost {\'(\'}@.lower:common.common.kr{\')\'}',
        yearlyRentalIncome: '@:common.common.yearlyRentalIncome',
        returnPercent: '@:common.common.returnPercent',
      },
    },

    rentalListingIndex: {
      title: '@:common.common.rentalListing.term',
      addListing: 'Tilføj @.lower:common.common.rentalListing.term',
    },

    salesListingIndex: {
      title: 'Salgsannoncer',
      addListing: 'Tilføj salgsannonce',
    },

    usersIndex: {
      title: 'Brugere',
      addUser: 'Tilføj @.lower:common.common.user.term',
    },
    usersShow: {
      title: 'Bruger',
      labels: {
        id: '@:common.common.id',
        email: '@:common.common.email',
        authId: '@:common.common.authId',
        account: 'Tilknyttede @.lower:common.common.account.term',
        deleteUser: 'Slet @.lower:common.common.user.term',
      },
      table: {
        name: '@:common.common.name',
        id: '@:common.common.id',
        role: '@:common.common.role',
        remove: '@:global.form.remove',
        noAccounts: 'Denne bruger er ikke tilknyttet nogen konti',
      },
      messages: {
        confirmRemoveAssociation: 'Er du sikker på du vil fjerne relation?',
        confirm: 'Er du sikker på denne @.lower:common.common.user.term skal slettes?',
        deleted: '@:common.common.user.term "{userid}" er slettet',
        failedToDelete: '@:common.common.user.term kunne ikke slettes',
        userNotFOund: '@:common.common.user.term "{userid}" eksisterer ikke!',
        accountAssociated: 'Kan ikke slette brugeren når denne bruger har en konto associeret',
      },
    },
  },

  // Components in components folder. Specific for the components
  component: {
    accountCard: {
      labels: {
        accountType: '@:common.common.account.type',
        email: '@:common.common.email',
        phoneNumber: '@:common.common.phoneNumber',
        organization: '@:common.common.organization.term',
      },
    },
    accountListingSupplierForm: {
      title: 'Tilføj @.lower:common.common.listingSupplier.term',
      labels: {
        listingSupplier: 'Vælg @.lower:common.common.listingSupplier.term',
      },
    },

    accountListingSuppliersList: {
      id: '@:common.common.id',
      authId: '@:common.common.authId',
      name: '@:common.common.name',
      addListingSupplierTitle: 'Tilføj en annonceleverandør til denne konto',
      confirm: 'Er du sikker på du vil fjerne relation?',
      noListingSuppliers: 'Denne konti har ingen annonceleverandører tilknyttet',
    },

    accountUserForm: {
      labels: {
        user: 'Vælg ny bruger',
        accountRole: 'Vælg @.lower:common.common.role',
      },
    },

    accountUserList: {
      email: '@:common.common.email',
      role: '@:common.common.role',
      authId: '@:common.common.authId',
      remove: '@:global.form.remove',
      confirm: 'Er du sikker på du vil fjerne relation?',
      userNotCreatedInAuth: 'Bruger har ikke oprettet sig endnu',
      addUserTitle: 'Tilføj en bruger til denne konto',
      noUsers: 'Denne konto har ingen brugere tilknyttet',
    },
    accountPermissionForm: {
      title: 'Opdater rettigheder',
      labels: {
        rentalListing: '@:common.common.rentalListing.term',
        investmentListing: '@:common.common.investmentListing.term',
        salesListing: '@:common.common.salesListing.term',
        officeSpaceListing: '@:common.common.officespaceListing.term',
        accountStatistics: '@:common.common.account.term statistik',
        marketStatistics: 'Markeds statistik',
        accountSettings: '@:common.common.account.term indstillinger',
        canPublish: 'Publisere',
        shownOnWebsite: 'Vis på webside',
      },
    },
    accountOrganizationList: {
      noOrganizations: 'Denne konto har ingen organisationer tilknyttet',
      id: '@:common.common.id',
      email: '@:common.common.email',
      name: '@:common.common.name',
    },

    organizationUserForm: {
      labels: {
        user: 'Vælg ny bruger',
        accountRole: 'Vælg @:common.common.role',
      },
    },

    organizationUserList: {
      email: '@:common.common.email',
      role: '@:common.common.role',
      authId: '@:common.common.authId',
      remove: '@:global.form.remove',
      confirm: 'Er du sikker på du vil fjerne relation?',
      addUserTitle: 'Tilføj en bruger til denne organisation',
    },

    apiLogsTable: {
      tableTitle: 'API logs',
      labels: {
        filterColumn: 'Filtrer på kolonne',
        filterValue: 'Filtrer på værdi',
        startDate: 'Start dato',
        endDate: 'Slut dato',
        requestId: 'Request ID',
        url: 'URL',
        userId: 'User ID',
        accountId: 'Accound ID',
        method: 'Method',
        status: 'Status',
        recievedAt: 'Modtaget',
      },
    },

    dawaAutocomplete: {
      labels: {
        address: '@:common.common.address.term',
        useAdvertisedAddress: 'Benyt en @.lower:common.common.advertisedAddress',
        advertisedAddress: '@:common.common.advertisedAddress',
      },
      messages: {
        dawaError: 'Noget gik galt',
      },
    },

    listingSupplierForm: {
      title: 'Tilføj annonceleverandør',
      labels: {
        name: '@:common.common.name',
        authId: '@:common.common.authId',
        email: '@:common.common.email',
      },
    },

    listingsClose: {
      title: {
        rental: 'Luk leje annonce',
        investment: 'Luk investerings annonce',
        officespace: 'Luk kontor annonce',
        sales: 'Luk salgs annonce',
      },
      labels: {
        address: '@:common.common.address.term',
        advertisedAddress: '@:common.common.advertisedAddress',
        accountOwnerListingIdentifierID: '@:common.common.accountOwnerListingIdentifierID',
        title: '@:common.common.title',
        flashline: '@:common.common.flashline',
        floorArea: '@:common.common.floorArea {\'(\'}@.lower:common.common.m2{\')\'}',
        secondaryArea: '@:common.common.secondaryArea {\'(\'}@.lower:common.common.m2{\')\'}',
        groundArea: '@:common.common.groundArea {\'(\'}@.lower:common.common.m2{\')\'}',
        listingtypeSubcategory: '@:common.common.listingtypeSubcategory',
        closedSince: 'Lukket siden',
        closingReason: 'Lukke årsager',
        closingReasonText: 'Beskrivelse af lukke årsager',
      },
    },

    listingsListTable: {
      labels: {
        accountOwnerListingIdentifierID: '@:common.common.accountOwnerListingIdentifierID',
        address: '@:common.common.address.term',
        status: '@:common.common.status',
        listingType: '@:common.common.listingType.term',
        createdAt: '@:common.common.createdAt',
      },

      listingTypes: {
        0: 'TODO: Hvad er type 0?',
        1: 'Leje',
        2: 'Investering',
        3: 'Salg',
        4: 'Kontor',
      },

      options: {
        edit: 'Rediger emne',
        copy: 'Kopier emne',
        statistics: 'Se statistik for emne',
        viewMainPage: 'Se emne på ejendomstorvet.dk',
        highlight: 'Fremhæv emne',
      },

      noData: 'Der blev ikke fundet nogen emner',
    },

    unsubscriptions: {
      labels: {
        title: '@:common.common.unsubscription.term',
      },
    },

    organizationForm: {
      title: 'Tilføj @.lower:common.common.organization.term',
      labels: {
        name: '@:common.common.name',
        email: '@:common.common.email',
      },
    },

    userCard: {
      labels: {
        authId: '@:common.common.authId',
        email: '@:common.common.email',
      },
    },
    userForm: {
      title: 'Tilføj bruger',
      labels: {
        authId: '@:common.common.authId',
        email: '@:common.common.email',
        emailVerified: 'Er @.lower:common.common.email bekræftet',
        termsAccepted: 'Har brugeren accepteret terms',
        termsVersion: 'Version',
      },
      messages: {
        failedToAdd: '@:global.errors.failedToAdd @.lower:common.common.user.term',
        added: '@:common.common.user.term er tilføjet',
      },
    },

    userList: {
      userNotCreatedInAuth: 'Bruger har ikke oprettet sig endnu',
    },

    userChangePasswordButton: {
      button: 'Skift kodeord',
      buttonIsSend: 'Email sendt',
      confirm: 'Vil du sende "request password change" til {email}',
      isSend: 'Email er sendt til {email}',
    },
    userChangePasswordForm: {
      submitButton: 'Nulstil kodeord',
      message: 'Efter du har klikket på knappen herover, vil du blive logget ud og bedt om at lave et nyt password',
    },
  },
  layout: {
    theNavigationBar: {
      title: 'Ejendomstorvet Admin',
      users: 'Brugere',
      organizations: 'Organisationer',
      accounts: 'Konti',
      listingSuppliers: 'Annonceleverandører',
      listings: '@:common.common.listing.term',
      apilogs: 'Apilogs',
      gotoEt: 'Gå til ejendomstorvet.dk',
      newListing: 'Nyt @.lower:common.common.listing.term',
      loggedOnAs: 'Logget på som {email}',
      login: 'Log ind',
      logout: 'Log ud',
    },
  },
};
