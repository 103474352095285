// @ts-check

import { useAuth as useAuthComposable } from 'vue-ui-kit';
import { authPlugin } from 'vue-ui-kit';
/**
 * @typedef {{
 * isAuthenticated: import('vue').Ref<boolean>,
 * isLoading: import('vue').Ref<boolean>,
 * user: import('vue').Ref<any|boolean>,
 * login: function(string, string) : Promise<void>,
 * logout: function() : void,
 * ready: function() : Promise<boolean>,
 * getToken: function() : string|null,
 * getPermissions: function() : string[] | []
  }} UseAuth */
/**
 * Plugin for vue `app.use(kinde);`
 * Should be installed with vue-router in options
 */
export default authPlugin;

// TODO: This actually uses the wrong service URL - if we are EVER to log in from the extranets site this needs
// to change
const useAuth = () => useAuthComposable(import.meta.env.VITE_AUTH_SERVICE_API_URL, {
  loginRedirectUri: import.meta.env.VITE_PATH_WWW + '/login',
  logoutRedirectUri: import.meta.env.VITE_PATH_WWW,
  useLocalStorage: false,
  cookie: {
    daysToExpiry: 14,
    domain: import.meta.env.VITE_COOKIE_DOMAIN,
    path: '/',
    secure: false,
  },
});

export { useAuth };
